import React, { useState,useEffect,useRef }  from 'react'
import Sidebar from '../Components/Sidebar'
import TaskCompo from '../Components/TaskCompo'

import { useSelector } from "react-redux";

import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import toast from "react-hot-toast";
import ServicesCompo from "../Components/ServicesCompo";
import ReqCompo from "../Components/ReqCompo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/loader/loader";
import { MutatingDots } from 'react-loader-spinner'
import DestinationAutoCompo from '../Components/reusable/DestinationAutoCompo';
import { ThreeDots } from 'react-loader-spinner'
import RoutingTaskCompo from "../Components/RoutingTaskCompo";
import AutoCompleteCompo from '../Components/reusable/AutoCompleteCompo';

import AutoCompleteDestComp from '../Components/reusable/AutoCompleteDestComp';


// import {
//   Switch,
//   Box,Stack
  
// } from '@mui/material';

import { Container, Box, Typography, Stack, Switch, Paper, Divider,Button } from '@mui/material';

function RoutingTasks() {

    //setting employee from state
    const employee = useSelector((state) => state.employee);
    const headers = {
          Authorization: `Bearer ${employee.token}`,
        };
  const closeRefServ = useRef();
  const serFormRef=useRef();
  const openSerModalRef=useRef();
  const [loading, setLoading] = useState(false);
  const [serviceEditData,setserviceEditData]=useState({});
  const [nocData,setNOCData]=useState([]);
  const [nocGroupData,setNOCGroupData]=useState([]);
  const [productType,setProductType]=useState(employee?.category)
  const[showIndiGroup,setShowIndiGroup]=useState("indi");

  const [loadComponent,SetLoadComponent] = useState(false);

  const [numSuppliers, setNumSuppliers] = useState(1);
  // const [suppliers, setSuppliers] = useState(Array(numSuppliers).fill(''));
  const [suppliers, setSuppliers] = useState(Array(numSuppliers).fill({ name: '', price: '' }));

  const [enteredCompany,setenteredCompany]=useState(); //used for getting from autocompleted


  const [selectedDestRoute1, setSelectedDestRoute1]=useState('');
  const [selectedDestRoute2, setSelectedDestRoute2]=useState('');




useEffect(()=>{
  setenteredCompany(serviceEditData?.customerName? serviceEditData?.customerName : '');
  setNumSuppliers(serviceEditData?.suppliers?.length ? serviceEditData?.suppliers?.length:1);
  // setSuppliers(serviceEditData?.suppliers? serviceEditData?.suppliers :Array(numSuppliers).fill(''));
  setSuppliers(serviceEditData?.suppliers? serviceEditData?.suppliers :Array(numSuppliers).fill( { name: '', price: '' }));
  setProductType(employee?.role==='admin'? serviceEditData?.product : employee?.category);
  setShowIndiGroup(serviceEditData?.assignedToModel==='EmployeeGroup'?'group':'indi')

  setSelectedDestRoute1(serviceEditData?.destinationName);
  setSelectedDestRoute2(serviceEditData?.destinationName2);

},[serviceEditData]);


  useEffect(()=>{

    const paramsNoc = {
            method: "GET",
            url:  productType==="VOICE"? `${url}/api/employeeActiveNOC_VOICE` :
            productType==="SMS"?  `${url}/api/employeeActiveNOC_SMS`:"" ,
            headers: headers,
          }; 
  
    if(paramsNoc?.url!==""){
        apiConnector(paramsNoc)
          .then((response) => {
            setNOCData(response.data);
              
          })
          .catch((error) => {
            console.error("Error fetching daily progress reports:", error);
            // setLoading(false);
          });
    }
  
  
  
    const paramsGroupNoc = {
      method: "GET",
      url:  productType==="VOICE"? `${url}/api/empgroupActive_VOICE` :
      productType==="SMS"?  `${url}/api/empgroupActive_SMS`:"" ,
      headers: headers,
    }; 
  
  if(paramsGroupNoc?.url!==""){
  apiConnector(paramsGroupNoc)
    .then((response) => {
      setNOCGroupData(response.data);
        
    })
    .catch((error) => {
      console.error("Error fetching daily progress reports:", error);
      // setLoading(false);
    });
  }
  
  },[productType,serviceEditData]);


  const resetEditForm = ()=>{
    serFormRef.current.reset();
   setserviceEditData({});
   setNumSuppliers(1);
   setSuppliers([]);

 }


 // Handle the change in the number of suppliers
 const handleNumSuppliersChange = (event) => {
   let value = parseInt(event.target.value, 10);
   if (value > 5) {
     value = 5;
   }
   setNumSuppliers(value);

   // Adjust the suppliers array based on the new number of suppliers
  setSuppliers(prevSuppliers => {
     const newSuppliers = prevSuppliers.slice(0, value);
     while (newSuppliers.length < value) {
      // newSuppliers.push('');
       newSuppliers.push({ name: '', price: '' });

     }
     return newSuppliers;
   });
 };

 // Handle the change in supplier names
 const handleSupplierChange = (index, event) => {
  //  const newSuppliers = [...suppliers];
  //  newSuppliers[index] = event.target.value;
  //  setSuppliers(newSuppliers);

   const { name, value } = event.target;
  const newSuppliers = [...suppliers];
  newSuppliers[index] = { ...newSuppliers[index], [name]: value };
  setSuppliers(newSuppliers);
 };



const handleSubmitRoutingtask = async (e) => {
      e.preventDefault();
      const formdata = new FormData(e.currentTarget);


    const taskSubject = formdata.get('taskSubject');
    //const customerName = formdata.get('customerName');
    const customerName = enteredCompany;

    // const destinationName = formdata.get('destinationName');
    const destinationName = selectedDestRoute1;

    const category = formdata.get('category');
    const price = formdata.get('price');

    // const destinationName2 = formdata.get('destinationName2');
    const destinationName2 = selectedDestRoute2;

    const price2 = formdata.get('price2');
    
    const remarks = formdata.get('remarks');
    let product = formdata.get('product');
    if(employee.role==='admin'){
      product = formdata.get('product');
      if (!product || product.trim() === '') {
        toast.error("Select Poduct Type Required!");
        setLoading(false);
        return false;
      }
    }else{
      product = employee.category;

    }


    // Validation for currency
    if (!taskSubject || taskSubject.trim() === '') {
      toast.error("Task Subject Required!");
      setLoading(false);
      return false;
    }
    
    if (!customerName || customerName.trim() === '') {
      toast.error("Customer Name Required!");
      setLoading(false);
      return false;
    }

    if (!destinationName || destinationName.trim() === '') {
      toast.error("Destination Name Required!");
      setLoading(false);
      return false;
    }

    const numberPattern =  /^[0-9]+(\.[0-9]+)?$/
    if (!price || price.trim() === '' || !numberPattern.test(price)) {
      toast.error("Price Required and must be number!");
      setLoading(false);
      return false;
    }

    if (!category || category.trim() === '') {
      toast.error("Category Required!");
      setLoading(false);
      return false;
    }
    

    

    if(showIndiGroup==="indi"){
      const asgnId=formdata.get('assignedTo');
      if(!asgnId || asgnId==="" || asgnId.length<1){
        toast.error("Select the NOC!");
        return false
      }
      formdata.append('groupFlag', "N");
    }else if(showIndiGroup==="group"){
      const groupId=formdata.get('assignedToGroup');
      if(!groupId || groupId==="" || groupId.length<1){
        toast.error("Select the Group!");
        return false
      }

      formdata.append('groupFlag', "Y");

    }else
    {
      toast.error("Some error occured in NOC Assign");
      return false;
  }



    if(suppliers.length !== numSuppliers){ 
      toast.error("Suppliers count mismatch!");
      setLoading(false);
      return false;
    }
      
   

    // suppliers?.forEach(item =>{
    //   alert(item)
    //     if(!item?.name || item?.name?.trim() === ''){
    //          toast.error("Suppliers name required!");
    //          setLoading(false);
    //         return false;
    //     }
    //      if (!item?.price || item?.price.trim() === '' || !numberPattern.test(item?.price)) {
    //       toast.error("Supplier Price Required and must be number!");
    //       setLoading(false);
    //       return false;
    //     }
    // });
  
    
    const hasErrors = suppliers?.some(item => {
      // Check if the item's name is valid
      if (!item?.name?.trim()) {
          toast.error("Supplier's name is required!");
          setLoading(false);
          return true; // Error found, stop further processing
      }
  
      // Check if the item's price is valid
      if ( !item?.price  || !numberPattern.test(item.price)) {
          toast.error("Supplier's price is required and must be a number!");
          setLoading(false);
          return true; // Error found, stop further processing
      }
  
      return false; // No errors for this item
  });
  
  if (hasErrors) {
      return false;
  }
  
   

  

  try {
    let response = await apiConnector({
      method: (serviceEditData && serviceEditData?._id) ? "PUT": "POST",
      url: (serviceEditData && serviceEditData?._id) ? `${url}/api/routingtask/${serviceEditData?._id}`  :  `${url}/api/routingtask`,
      bodyData: {
        taskSubject:taskSubject,
        customerName:customerName,
        destinationName: destinationName,
        suppliers:suppliers,
        product:product,
        category: category,
        price: price,

        destinationName2: destinationName2,
        price2: price2,

        remarks:remarks,
        assignedTo: formdata.get('assignedTo'), 
        assignedToGroup :formdata.get('assignedToGroup'),
        groupFlag:formdata.get('groupFlag')
      },
      headers: headers,
    });


    
    if (response.status === 201) {
     SetLoadComponent(!loadComponent);
      closeRefServ.current.click();
      serFormRef.current.reset();
      toast.success("Routing Task Added successfully");
    }else if (response.status === 200) {
      SetLoadComponent(!loadComponent);
      toast.success("Routing Task Updated successfully");
      closeRefServ.current.click();
      serFormRef.current.reset();
      setserviceEditData({});
    }else{
        toast.error("Some Error Occured");
    }
    setLoading(false);

  } catch (error) {
    setLoading(false);
    console.error('Error submitting form:', error);
    toast.error(error?.response?.data?.message);
  }

    

  }


  return (

    <Sidebar>
      <div className='container-fluid'> 
             <h2>Routing Task Creation and Assign</h2>
        <div className="row mt-3">
          <div className="flex justify-between mb-2">
          {/* <h1 className="h5 mb-3 text-gray-800 justify-content">
            Create and Assign
          </h1> */}
        </div>

        <div className="col-sm-12">
          <div className="row mt-3 ">
             { (employee.role==='admin' || employee.role==='Account Manager')  && <button
                  type="button"
                  className=" btn bg-blue-700 text-sm text-white border-white shadow-lg mb-2"
                  data-toggle="modal"
                  data-target="#addNewAccModal"
                  ref={openSerModalRef}
                >
                  Create Routing Task
                  <FontAwesomeIcon className="ml-1" icon={faPlus} />
                </button>}   

            
           {/* Form start */}
              <div
                className="modal  fade "
                id="addNewAccModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addNewAccModalTitle"
                aria-hidden="true"
                data-backdrop="static"
              >
                <div
                  className="modal-dialog modal-dialog-centered "
                  role="document"
                >
                  <form    onSubmit={handleSubmitRoutingtask} 
                  ref={serFormRef}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h2 className="font-semibold text-xl text-gray-600">
                          {serviceEditData?._id ? "Update":"New"} Routing Task Addition 
                        </h2>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={closeRefServ}
                          onClick={resetEditForm}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row    p-1  flex items-center justify-center ">
                          <div className="container  ">
                            <div>
                              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                  <div className="lg:col-span-4">
                                    <div className="grid text-black gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-6">
                                    </div>
                                     
                                    <div className=" md:col-span-6">
                                        <label htmlFor="subject">
                                          Subject:
                                        </label>
                                        <input
                                          type="text"
                                          id="taskSubject"
                                          name="taskSubject"
                                          className="form-control"
                                          defaultValue={serviceEditData.taskSubject}
                                        />
                                      </div>

                                      <div className=" md:col-span-6">
                                        <label htmlFor="customerName">
                                        Customer Name:
                                        </label>
                                        {/* <input
                                          type="text"
                                          id="customerName"
                                          name="customerName"
                                          className="form-control"
                                          defaultValue={serviceEditData?.customerName}
                                        /> */}
                                 <AutoCompleteCompo   defaultValue={enteredCompany} setenteredCompany={setenteredCompany}/>


                                      </div>

                                      <div className=" md:col-span-3">
                                        <label htmlFor="customerName">
                                        Destination 1:
                                        </label>
                                        
                                        {/* <DestinationAutoCompo  defaultDestination={selectedDestRoute1} setSelectedDest={setSelectedDestRoute1}   /> */}
                                        <AutoCompleteDestComp  defaultValue={selectedDestRoute1} setenteredCompany={setSelectedDestRoute1}   />

                                        

                                        {/* <input
                                          type="text"
                                          id="destinationName"
                                          name="destinationName"
                                          className="form-control"
                                          defaultValue={serviceEditData.destinationName}
                                        /> */}
                                      </div>

                 
                                      <div className=" md:col-span-3">
                                        <label htmlFor="currency">
                                          Price:
                                        </label>
                                        <input
                                          type="text"
                                          id="price"
                                          name="price"
                                          className="form-control"
                                          defaultValue={serviceEditData.price}
                                        />
                                     
                                      </div> 

                                    



                            {/* added */}
                                      <div className=" md:col-span-3">
                                        <label htmlFor="currency">
                                        Destination 2  :
                                        </label>

                                        {/* <DestinationAutoCompo  defaultDestination={selectedDestRoute2} setSelectedDest={setSelectedDestRoute2}   /> */}
                                        <AutoCompleteDestComp  defaultValue={selectedDestRoute2} setenteredCompany={setSelectedDestRoute2}   />

                                        {/* <input
                                          type="text"
                                          id="destinationName2"
                                          name="destinationName2"
                                          className="form-control"
                                          defaultValue={serviceEditData?.destinationName2}
                                        /> */}
                                     
                                      </div> 

                                      <div className=" md:col-span-3 ">
                                        <label htmlFor="category">
                                         Price:
                                        </label>
                                          <input
                                            type="text"
                                            id="price2"
                                            name="price2"
                                            className="form-control"
                                            defaultValue={serviceEditData?.price2}
                                          />
                                      </div>
                          {/* Added  */}

                          <div className=" md:col-span-6 ">
                                        <label htmlFor="category">
                                         Category:
                                        </label>
                                          <input
                                            type="text"
                                            id="category"
                                            name="category"
                                            className="form-control"
                                            defaultValue={serviceEditData.category}
                                          />
                                      </div>

      
                          { employee?.role==="admin" &&
                                 <>    <div className="  md:col-span-6 flex items-center">
                                        <label className='w-2/5' htmlFor="product">
                                          Product:
                                        </label>
                                        <select   id="product"
                                        name="product"
                                        className=" form-control col-sm-8 w-2/5"  onChange={(e)=> setProductType(e.target.value)} >
                                          <option>Select Product Type :</option>
                                          <option value="VOICE"  selected={serviceEditData?.product==="VOICE"}>VOICE</option>
                                          <option value="SMS" selected={serviceEditData?.product==="SMS"}>SMS</option>
                                        </select>
                                     </div>
                                       
                                      </>  }

                        <div className="md:col-span-6 flex items-center" >
                              <label htmlFor="assignedTo" className='w-2/5'>Assigned To:</label>
                                
                                {/* <div className="w-2/5 col-sm-12 p-1">  */}
                                   <input type="radio" value="indi" name="giRadio"  checked={showIndiGroup === 'indi' }  onChange={()=>setShowIndiGroup("indi")} className=" w-1.5/5"/> &nbsp;Individual &nbsp;&nbsp;
                                    <input type="radio" value="group" name="giRadio"  checked={showIndiGroup === 'group' }  onChange={()=>setShowIndiGroup("group")} className="w-1.5/5"/>&nbsp; Group
                                {/* </div> */}
                         </div>     
                         { ( showIndiGroup ==="group" )&&    <select
                                className="form-control md:col-span-6"
                                name="assignedToGroup"
                              >
                                <option value="">Select Group</option>
                                {nocGroupData &&
                                  nocGroupData.map((item, index) => (
                                    <option key={index} value={item._id} 
                                     selected={   (serviceEditData && serviceEditData?.assignedTo?._id ===item._id) ? true:false  } 
                                      >
                                      {item.name}[{item?.empList?.length} Member - {item.productCategory}]
                                    </option>
                                  ))}
                              </select>}

                            { (showIndiGroup==="indi" )&& <select
                                className="form-control md:col-span-6"
                                name="assignedTo"
                              >
                                <option value="">Select NOC</option>
                                {nocData &&
                                  nocData.map((item, index) => (
                                    <option key={index} value={item._id} 
                                     selected={   (serviceEditData.assignedTo?._id ===item._id) ? true:false  } 
                                      >
                                      {item.name}[{item.email} -
                                      {item.productCategory}]
                                    </option>
                                  ))}
                              </select>}

                           

                                      <div className="md:col-span-6 flex items-center">
                                            <label className="w-2/5">
                                              Suppliers (max 5):
                                            </label>
                                            <select
                                                value={numSuppliers}
                                                onChange={handleNumSuppliersChange}
                                                className="form-control w-3/5"
                                              >
                                                {[...Array(5)].map((_, index) => (
                                                  <option key={index + 1} value={index + 1}>
                                                    {index + 1}
                                                  </option>
                                                ))}
                                              </select>

                                          </div>
                                  <div className=" md:col-span-6 ">
                                      
                                        {/* {Array.from({ length: numSuppliers }).map((_, index) => (
                                          <div key={index} className='flex items-center' >
                                            <label className='w-1/5'>
                                              Supplier {index + 1}:
                                              </label> 
                                              <input
                                                type="text"
                                                 className="form-control w-4/5"
                                                value={suppliers[index]}
                                                onChange={(event) => handleSupplierChange(index, event)}
                                              />
                                           
                                          </div>
                                        ))}     */}
                                    

                                  {Array.from({ length: numSuppliers }).map((_, index) => (
                                    <div key={index} className='flex items-center'>
                                      <label className='w-2/5'>
                                        Supplier {index + 1}:
                                      </label>
                                      <input
                                        type="text"
                                        name="name"
                                        placeholder="Supplier Name"
                                        className="form-control w-4/5"
                                        value={suppliers && suppliers[index]?.name}
                                        onChange={(event) => handleSupplierChange(index, event)}
                                      />
                                      <input
                                        type="text"
                                        name="price"
                                        placeholder="Price"
                                        className="form-control w-3/5 ml-2"
                                        value={suppliers && suppliers[index]?.price}
                                        onChange={(event) => handleSupplierChange(index, event)}
                                      />
                                    </div>
                                  ))}
                             </div>

                                      <div className="md:col-span-6  m-2">
                                      <label htmlFor="remarks">Remarks:</label>
                                      <textarea
                                        type="text"
                                        id="remarks"
                                        name="remarks"
                                        className=" form-control"
                                        defaultValue={  serviceEditData?.remarks ? serviceEditData?.remarks :''}
                                    
                                      ></textarea>
                                    </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer md:col-span-7 flex justify-between  ">
                        <button
                          type="button"
                          className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                          data-dismiss="modal" onClick={resetEditForm}
                        >
                          Close
                        </button>

                        <button
                          className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                          type="submit"
                        >
                          {loading ? <Loader /> : serviceEditData?._id ? "UPDATE" : "ADD"}
                        </button>
                      </div>
                    </div>{" "}
                  </form>
                </div>
              </div>

              {/*  form end */}
              </div>
        </div>
      </div>



<h2 className='text-bold text-black'> {employee.role==='NOC Manager' ? "Routing Task Assigned to You" :"Routing Task Assigned by You"}  </h2>


<br/>
      <RoutingTaskCompo 
      // accountIdProps={id} 
      reload={loadComponent} openModal={openSerModalRef}  
      fetchType ={ employee.role==='NOC Manager' ? "ASSIGNED_TO_ME" :"ASSIGNED_FROM_ME"}
       serviceEditData={ serviceEditData} setserviceEditData={setserviceEditData} 
                // setShowGhostPriceBox={setShowGhostPriceBox}
                />


<br/><br/>

<h1 className='text-bold text-black'> All Routing Task</h1>

<br/>

<RoutingTaskCompo 
      // accountIdProps={id} 
      reload={loadComponent} openModal={openSerModalRef}  
      fetchType ={"ALL_TASK"}
       serviceEditData={ serviceEditData} setserviceEditData={setserviceEditData} 
                // setShowGhostPriceBox={setShowGhostPriceBox}
                />


      </div>
    </Sidebar>
   
  )
}

export default RoutingTasks;