import React, { useState,useEffect,useRef } from 'react';
import { useSelector } from "react-redux";
import { useLocation  } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import { url } from "../../services/api";
import { apiConnector } from "../../services/apiConnector";
import toast from "react-hot-toast";
import ServicesCompo from "../Components/ServicesCompo";
import ReqCompo from "../Components/ReqCompo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/loader/loader";
import { MutatingDots } from 'react-loader-spinner'
import DestinationAutoCompo from '../Components/reusable/DestinationAutoCompo';
import { ThreeDots } from 'react-loader-spinner'

// import {
//   Switch,
//   Box,Stack
  
// } from '@mui/material';

import { Container, Box, Typography, Stack, Switch, Paper, Divider,Button } from '@mui/material';


const Account = () => {
  //getting id from url query parameter
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  //setting employee from state
  const employee = useSelector((state) => state.employee);
  const headers = {
        Authorization: `Bearer ${employee.token}`,
      };
   
    //useRef
  const closeRefServ = useRef();
  const closeRefReq = useRef();
  const reqForRef =useRef();
  const serFormRef=useRef();
  const openSerModalRef=useRef();
  const openReqModalRef =useRef();
  const openFileResetRefREQ =useRef();
  const textareaRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [screenLoad,setScreenLoad]=useState(true);
  const [loadComponent,SetLoadComponent] = useState(false);
  const [account,setAccount]=useState({});
  const [empList, setEmpList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [assignedValue,setAssignedValue]=useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [serviceEditData,setserviceEditData]=useState({});
  const [reqEditData,setReqEditData]=useState(null);
  const[currency,setCurrency]=useState([]);
  const[qCategory,setQCategory]=useState([]);

 const [showGhostPriceBox,setShowGhostPriceBox]=useState(false)

  //fetching account details by ID
  useEffect(() => {
    
          const params = {
            method: "GET",
            url: `${url}/api/account/${id}`,
            headers: headers,
          }; 
        apiConnector(params)
          .then((response) => {
              setAccount(response.data);
          })
          .catch((error) => {
            console.error("Error fetching daily progress reports:", error);
            // setLoading(false);
          });
    }, [refresh]);

        
  // fetching employees for Select List
  useEffect(() => {
          const params = {
            method: "GET",
            url: (account.accountType==='VOICE') ? `${url}/api/employeeActiveAM_VOICE` : 
            (account.accountType==='SMS')? `${url}/api/employeeActiveAM_SMS` :"" ,
            headers: headers,
          };

          if(params.url!==""){
        apiConnector(params)
          .then((response) => {
              setEmpList(response.data);
              setAssignedValue(response.data.assignedTo?._id)
          })
          .catch((error) => {
            console.error("Error fetching daily progress reports:", error);
            // setLoading(false);
          });
        }

          
    }, [account?.accountType]);



  useEffect(() => {


    const paramsQualityCategory = {
      method: "GET",
      url: `${url}/api/qualityCategoryActive`,
      headers: headers,
    };
    const paramsCurrency = {
      method: "GET",
      url: `${url}/api/currencyActive`,
      headers: headers,
    };


 

    apiConnector(paramsQualityCategory)
    .then((response) => {
      setQCategory(response.data);   
    })
    .catch((error) => {
      console.error("Error fetching List : ", error);
    });

    apiConnector(paramsCurrency)
    .then((response) => {
      setCurrency(response.data);   
    })
    .catch((error) => {
      console.error("Error fetching List : ", error);
    });


    const delay = setTimeout(() => {
      setScreenLoad(false)
    
  }, 1200);

  // Clean up the timeout to avoid memory leaks
  return () => clearTimeout(delay);
    
},[]);



 // For change in Account Assigned
  const handleChangeAssignedTo = async () => {
      try {
        let response = await apiConnector({
          method: "PUT",
          url: `${url}/api/account/changeAssignedTo`,
          bodyData: {
              accountId: account._id,
              assignedTo: assignedValue,
              empId : employee._id,
          },
          headers: headers,
        });
    
        if (response.status === 200) {
          toast.success("Assigned Successfully!")
          setRefresh(!refresh);
        }else{
          toast.error("Not Assinged Error Occured");
        }
      } catch (err) {
        console.log("ERROR>>>>", err);
      }
      setShowDropdown(false);
    };

  //for account assigned dropdown
  const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
    };

 //for render dropdown
  const renderDropdown = () => (
          <>
          <select value={assignedValue || ''} onChange={(e) => setAssignedValue(e.target.value)}>
            <option value="">Select an employee</option>
            <option value={`${employee._id}`}> SELF</option>
            {empList.map((employee) => (
              <option key={employee._id} value={employee._id}>
                {employee.name}[{employee.role}-{employee.email}]
              </option>
            ))}
          </select> 
          <button className='btn bg-dark text-white text-bold mr-2 ml-2'  onClick={handleChangeAssignedTo} >Assign</button>
          <button className='btn bg-red-500 text-white text-bold' onClick={toggleDropdown}>Cancel</button>
          </>
        );

  //for submitting new Requirement
  const [selectedDest, setSelectedDest]=useState('');
  useEffect(()=>{ 

    if(reqEditData)
      setSelectedDest(reqEditData?.destinationName?._id);

   },[reqEditData])

  const handleSubmitReq = async (e) => {
          e.preventDefault();
          setLoading(true);
          const formdata = new FormData(e.currentTarget);
          // const destinationName = formdata.get('destinationName'); 
          const destinationName =  selectedDest;
          const currency = formdata.get('currency');
          const qualityCategory = formdata.get('qualityCategory');
          const startPricing = formdata.get('startPricing');
          const endPricing = formdata.get('endPricing');
          const volume = formdata.get('volume');
          const remarks = formdata.get('remarks');
         // Validation for destinationName
          if (!destinationName || destinationName.trim() === '') {
            toast.error("Destination Required!");
            setLoading(false);
            return false;
          }

          // Validation for currency
          if (!currency || currency.trim() === '') {
            toast.error("Currency Required!");
            setLoading(false);
            return false;
          }

          // Validation for qualityCategory
          if (!qualityCategory || qualityCategory.trim() === '') {
            toast.error("Quality Category Required!");
            setLoading(false);
            return false;
          }


           // Validation for volume
           if (!volume || volume.trim() === '') {
            toast.error("Volume Required!");
            setLoading(false);
            return false;
          }
          if (isNaN(volume)) {
            toast.error("Volume must be a number!");
            setLoading(false);
            return false;
          }

          // Validation for startPricing and endPricing
          const numberPattern =  /^[0-9]+(\.[0-9]+)?$/
          if (!startPricing || startPricing.trim() === '' || !numberPattern.test(startPricing)) {
            toast.error("Start Pricing Required and must be number!");
            setLoading(false);
            return false;
          }
          if (!endPricing || endPricing.trim() === '' || !numberPattern.test(endPricing)) {
            toast.error("End Pricing Required and must be number!");
            setLoading(false);
            return false;
          }
          if (parseFloat(startPricing) > parseFloat(endPricing)) {
            toast.error("Start Pricing must be equal or less than End Pricing!");
            setLoading(false);
            return false;
          }

         


            try {
              //  console.log('Form data:', formDataReq);
              let response = await apiConnector({
                method: reqEditData && reqEditData?._id ? "PUT" :  "POST",
                url:  reqEditData && reqEditData?._id ?  `${url}/api/requirements/${reqEditData?._id}` :  `${url}/api/requirements`,
                bodyData: {
                  destinationName:  destinationName,
                  currency: currency,
                  product : account.accountType,
                  qualityCategory: qualityCategory,
                  pricingRange:[startPricing,endPricing],
                  volume:volume,
                  createdBy: employee._id,
                  accountId:id,
                  remarks:remarks
                },
                headers: headers,
              });
      
  
              if (response.status === 201) {
                SetLoadComponent(!loadComponent);
                toast.success("Requirement Added successfully");
                closeRefReq.current.click();
                reqForRef.current.reset(); 
                setSelectedDest('');
              }else if (response.status === 200) {
                setReqEditData(null);
                SetLoadComponent(!loadComponent);
                toast.success("Requirement updated successfully");
                closeRefReq.current.click();
                reqForRef.current.reset(); 
                setSelectedDest('');
              }else{
                  toast.error("Not Added, Some Error");
              }
              setLoading(false);
            } catch (error) {
              setLoading(false);
              toast.error("Error ",error?.response?.data?.message);
              console.error('Error submitting form:', error?.response?.data?.message);
             
            }
  
        };




 //for submitting new  Services

 const [selectedDestRoute, setSelectedDestRoute]=useState('');

 useEffect(()=>{ 

    if(serviceEditData)
      setSelectedDestRoute(serviceEditData?.destinationName?._id);

  },[serviceEditData]);

  const handleSubmitServices = async (e) => {
          setLoading(true);
          e.preventDefault();
          const formdata = new FormData(e.currentTarget);
          // const  destinationName  = formdata.get('destinationName');
          const  destinationName  = selectedDestRoute;

          const currency = formdata.get('currency');
          const qualityCategory = formdata.get('qualityCategory');
          const pricing = formdata.get('pricing');
          const ghostPricing = formdata.get('ghostPricing');
          const ghostVisible = formdata.get('ghostVisible');
          let _product = account.accountType;
          const mediaType=formdata.get('mediaType');
          const remarks = formdata.get('remarks');


          if (!destinationName || destinationName.trim() === '') {
            toast.error("Destination Required!");
            setLoading(false);
            return false;
          }
          if (!currency || currency.trim() === '') {
            toast.error("Currency Required!");
            setLoading(false);
            return false;
          }
          if (!qualityCategory || qualityCategory.trim() === '') {
            toast.error("QualityCategory Required!");
            setLoading(false);
            return false;
          }
          const numberPattern =  /^[0-9]+(\.[0-9]+)?$/
          if (!pricing || pricing.trim() === '' || !numberPattern.test(pricing)) {
            toast.error("Enter Pricing-only numbers!");
            setLoading(false);
            return false;
          }
          if (employee.role==='admin' && ( ghostVisible===null || ghostVisible===undefined )) {
            toast.error("Choose Ghost Visible!");
            setLoading(false);
            return false;
          }

          if (employee.role==='admin' && ( ghostVisible==="true" && ( !ghostPricing || ghostPricing.trim() === '' || !numberPattern.test(ghostPricing) || ghostPricing<=pricing ) )) {
            toast.error("Enter Ghost Price - only numbers and must be greater than pricing!");
            setLoading(false);
            return false;
          }

          if (!mediaType || mediaType.trim() === '') {
            toast.error("MediaType Required!");
            setLoading(false);
            return false;
          }

            try {
              let response = await apiConnector({
                method: (serviceEditData && serviceEditData?._id) ? "PUT": "POST",
                url: (serviceEditData && serviceEditData?._id) ? `${url}/api/services/${serviceEditData?._id}`  :  `${url}/api/services`,
                bodyData: {
                  destinationName: destinationName,
                  currency : currency,
                  product:_product,
                  qualityCategory: qualityCategory,
                  pricing: pricing,
                  ghostPricing: ghostPricing,
                  ghostVisible:ghostVisible,
                  createdBy: employee._id,
                  mediaType:mediaType,
                  remarks:remarks,
                  accountId:id,
                  updatedBy: employee._id
                },
                headers: headers,
              });
      

              
              if (response.status === 201) {
                SetLoadComponent(!loadComponent);
                closeRefServ.current.click();
                serFormRef.current.reset();
                setSelectedDestRoute('');
                toast.success("Service Added successfully");
              }else if (response.status === 200) {
                SetLoadComponent(!loadComponent);
                toast.success("Service Updated successfully");
                closeRefServ.current.click();
                serFormRef.current.reset();
                setserviceEditData({});
              }else{
                  toast.error("Some Error Occured");
              }
              setLoading(false);

            } catch (error) {
              setLoading(false);
              console.error('Error submitting form:', error);
              toast.error(error?.response?.data?.message);
            }
  
        };


const resetEditFormDataService = ()=>{
     serFormRef.current.reset();
    setserviceEditData({});
    setShowGhostPriceBox(false);
    setSelectedDestRoute('')

  }
  
  const resetFormDataReq =()=>{
    reqForRef.current.reset();
    
    setSelectedDest('');
    if (textareaRef.current) {
      textareaRef.current.value = '';
    }
    setReqEditData(null)
    
  }
   
  const handleDisable = async () =>  {
  
    try {
      const response = await apiConnector({
        method: "PUT",
        url: `${url}/api/account/disable/${account?._id}`, // Assuming user._id exists
        headers: headers,
      });
  
      // Check if the API call was successful
      if (response.status === 200) {
       
        toast.success(`${response?.data?.message}`);
         setRefresh(!refresh);
      }else{
        toast.error("Some error occured!")
      }
    } catch (error) {
      toast.error(`Error : ${error?.response?.data?.message}`);
    }
  
  }


  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [validationErros,setValidationErros]=useState(null)
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setErrorMessage( 'Please select an XIsx file');
        setSelectedFile(null);
        } 
        else if (file.size > 2 * 1024 * 1024) {
          setErrorMessage('File size should be less than 2 MB');
          setSelectedFile(null);
        } else 
        {
        setSelectedFile(file);
        setErrorMessage("");
       
        }
      }
    }


    const [selectedFile_SER, setSelectedFile_SER] = useState(null);
    const [errorMessage_SER, setErrorMessage_SER] = useState("");
    const [validationErros_SER,setValidationErros_SER]=useState(null)
      const handleFileChange_SER = (event) => {
    const file = event.target.files[0];
    if (file) {
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        setErrorMessage_SER( 'Please select an XIsx file');
        setSelectedFile_SER(null);
        } 
        else if (file.size > 2 * 1024 * 1024) {
          setErrorMessage_SER('File size should be less than 2 MB');
          setSelectedFile_SER(null);
        } else 
        {
        setSelectedFile_SER(file);
        setErrorMessage_SER("");
         }
      }
    }

const [processing,setProcessing]=useState(false);
const handleSubmit = async (e) => {
       
       if (selectedFile) {
      
        const formData = new FormData();
          setProcessing(true)  
          formData.append ('file',selectedFile );
        formData.append ('accountId', account._id);
        formData.append ('productType', account.accountType);
        try {
          let response = await apiConnector ({
          method: "POST", 
          url: `${url}/api/reqFile` ,
           bodyData:formData,
          headers: {  Authorization: `Bearer ${employee.token}`,'Content-Type': 'multipart/form-data', },
          });

          if (response?.status===200) {
            SetLoadComponent(!loadComponent);
            toast.success("Successfully Data uploaded");
            setRefresh(!refresh);
            if( openFileResetRefREQ.current)
             openFileResetRefREQ.current.value='';
            
            setValidationErros(null)
            setErrorMessage("")
            setSelectedFile(null)
            setProcessing(false);
          }else{
           toast.error(`Some error : ${response?.data?.message}`);
           setProcessing(false);
          }
        } catch (error) {
          toast.error(`${error?.response?.data?.message}`);
          setValidationErros(error?.response?.data?.errors);
          console.error ('Error uploading file:', error);
          setProcessing(false)  
        } 
        setSelectedFile(null);
      } else {
        setErrorMessage ('Please select file before submitting');
       }
    }


    const [processing_SER,setProcessing_SER]=useState(false);

    const handleSubmit_SERFILE = async (e) => {
      if (selectedFile_SER) {
        setProcessing_SER(true) 
        const formData = new FormData();
         formData.append ('file', selectedFile_SER);
       formData.append ('accountId', account._id);
       formData.append ('productType', account.accountType);
       try {
         let response = await apiConnector ({
         method: "POST", 
         url: `${url}/api/serFile`    ,
        bodyData:formData,
         headers: {  Authorization: `Bearer ${employee.token}`,'Content-Type': 'multipart/form-data', },
         });

         if (response?.status===200) {
           SetLoadComponent(!loadComponent);
           toast.success("Successfully Data uploaded");
           setRefresh(!refresh);
           if( openFileResetRefREQ.current)
            openFileResetRefREQ.current.value='';
           
           setValidationErros_SER(null)
           setErrorMessage_SER("")
           setSelectedFile_SER(null)
           setProcessing_SER(false);
         }else{
          toast.error(`Some error : ${response?.data?.message}`);
          setProcessing_SER(false);
         }
       } catch (error) {
         toast.error(`${error?.response?.data?.message}`);
         setValidationErros_SER(error?.response?.data?.errors);
         console.error ('Error uploading file:', error);
         setProcessing_SER(false);
       } 
       setSelectedFile_SER(null);
     } else {
       setErrorMessage_SER ('Please select file before submitting');
      }
   }

     

    
  
return (
  <Sidebar>
   {screenLoad ?  <MutatingDots
  visible={true}
  height="100"
  width="100"
  color="#4fa94d"
  secondaryColor="#4fa94d"
  radius="12.5"
  ariaLabel="mutating-dots-loading"
  wrapperStyle={{'display': "grid", "place-items": "center", height: "100vh"}}
  wrapperClass=""
  />:

    <div className="container-fluid">
      <h2>Account Details</h2>
      {/* <div className="container row">
        <div className="mt-10 border-left-primary ">
          <div className=' flex justify-between'>
          <h4 className="h5 text-black">Account Name :{account.name} [ {account.accountType}]  
        
          
            </h4>


<Stack direction="row" spacing={1}>
<Box
            component="span"
            sx={(theme) => ({
              backgroundColor:
              account.status ===false
                  ? theme.palette.error.dark : theme.palette.success.dark,
              borderRadius: '0.25rem',
              color: '#fff',
             // maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {account.status ?"ENABLED":"DISABLED"}
          </Box>
          <Switch checked={account.status}  onClick={handleDisable} />
</Stack>
           
          </div>

      <div className='row  text-black'>


      <div className='flex col-sm-12'>
            <p className="col-sm-6">Account Name:  <b>  {account?.name}</b>  </p>  
            <p className="col-sm-6">Ghost  Name:   <b>  {account?.ghostName} </b> </p>    
          
            </div>

            <div className='flex col-sm-12'>
            <p className="col-sm-6">Email:  <b>  {account?.email}</b>  </p>  
            <p className="col-sm-6">Phone:   <b>  {account?.phone} </b> </p>    
          
            </div>

            <div className='flex col-sm-12'>
            <p className="col-sm-6">Company:  <b>  {account?.companyName}</b>  </p>  
            <p className="col-sm-6">Ghost  Name:   <b>  {account?.ghostName} </b> </p>    
          
            </div>
          <div className='col-sm-12'>
               <p className="col-sm-12"> Address:  <b>   {account?.address}, {account?.country?.name} </b>  </p>  
          </div>


          <div class="flex items-center col-sm-12 mt-2 mb-2">
                <div class="flex-grow border-b border-gray-300"></div>
                <div class="mx-4 text-black uppercase">Billing Details</div>
                <div class="flex-grow border-b border-gray-300"></div>
              </div>
          <div className='flex col-sm-12'>
             <p className=" col-sm-6">Billing Email:  <b>   {account?.billingEmail}</b>  </p>  
              <p className="col-sm-6 ">Sales Email:  <b>  {account?.salesEmail}</b>  </p> 
         </div>
          
         <div className='flex col-sm-12'>
              <p className=" col-sm-6">Payment Cycle :   <b>  {account?.paymentCycle?.name} </b> </p> 
              <p className=" col-sm-6">Time Zone:   <b>  {account?.timeZone?.name} </b> </p> 
         </div>
         <div className='flex col-sm-12'>
              <p className=" col-sm-6">Skype Id :   <b>  {account?.skypeId} </b> </p> 
              <p className=" col-sm-6">Currency:   <b>  {account?.currency?.name} </b> </p> 
         </div>

      </div>
      <div class="flex items-center col-sm-12 mt-2 mb-2">
                <div class="flex-grow border-b border-gray-300"></div>
                <div class="mx-4 text-black uppercase">Assigned AM </div>
                <div class="flex-grow border-b border-gray-300"></div>
              </div>
        
          <div>
            {employee && employee.role === "admin" ? (
              (account.assignedTo === null &&
                renderDropdown())  ||
              (account.assignedTo !== null && (
                <div className="text-black h6 ">
                  Assigned to: {account.assignedTo?.name}[
                  {account.assignedTo?.role } {account.assignedTo?.productCategory} - {account.assignedTo?.email}]
                  <button
                    className="btn bg-dark ml-2 text-white btn-sm"
                    hidden={showDropdown}
                    onClick={toggleDropdown}
                  >
                    Change
                  </button>
                </div>
              ))
            ) : (
              <div className="text-black">
                Assigned to: {account.assignedTo?.name} [
                {account.assignedTo?.role} - {account.assignedTo?.email}]{" "}
              </div>
            )}
            {showDropdown && renderDropdown()}
          </div>
        </div>

        <br></br>
      </div> */}


{/* ------------------ */}


<Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, boxShadow: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" component="div" color="text.primary">
              Account Name: {account.name} [{account.accountType}]
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box
                sx={(theme) => ({
                  backgroundColor: account.status ? theme.palette.success.dark : theme.palette.error.dark,
                  borderRadius: '0.25rem',
                  color: '#fff',
                  p: '0.25rem 0.5rem',
                })}
              >
                {account.status ? "ENABLED" : "DISABLED"}
              </Box>
              <Switch checked={account.status} onClick={handleDisable} color={'success'} />
            </Stack>
          </Stack>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">
                Account Name: <b>{account?.name}</b>
              </Typography>
              <Typography variant="body1">
                Company Name: <b>{  employee.role==='admin'? account?.companyName :  account?.ghostName  }</b>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">
                Email: <b>{  employee.role==='admin'?  account?.email:'XXXXXXXXXXX'}</b>
              </Typography>
              <Typography variant="body1">
                Phone: <b>{  employee.role==='admin'?  account?.phone : 'xxxxxxxxxx'}</b>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              {/* <Typography variant="body1">
                Company: <b>{account?.companyName}</b>
              </Typography> */}
          {    employee.role==='admin'&& <Typography variant="body1">
                Ghost Name: <b>{account?.ghostName}</b>
              </Typography>}
            </Box>
            <Typography variant="body1">
              Address: <b>{account?.address}, {account?.country?.name}</b>
            </Typography>
          </Stack>
        </Box>

        <Divider>
          <Typography variant="subtitle1" color="text.secondary">
            Billing Details
          </Typography>
        </Divider>

        <Box sx={{ my: 3 }}>
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">
                Billing Email: <b>{  employee.role==='admin'?  account?.billingEmail : 'xxxxxxxxxxx'}</b>
              </Typography>
              <Typography variant="body1">
                Sales Email: <b>{  employee.role==='admin'? account?.salesEmail : 'xxxxxxxxxxxx'}</b>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">
                Payment Cycle: <b>{account?.paymentCycle?.name}</b>
              </Typography>
              <Typography variant="body1">
                Time Zone: <b>{account?.timeZone?.name}</b>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body1">
                Skype Id: <b>   {employee.role==='admin'? account?.skypeId : 'xxxxxxxxxxxx'} </b>
              </Typography>
              <Typography variant="body1">
                Currency: <b>{account?.currency?.name}</b>
              </Typography>
            </Box>
          </Stack>
        </Box>

        <Divider>
          <Typography variant="subtitle1" color="text.secondary">
            Remarks 
          </Typography>
        </Divider>


        <Box sx={{ my: 3 }}>
          <Stack spacing={2}>
            <Box display="flex"  justifyContent="space-between" > 
              <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                Remarks : <b>{  account?.remarks}</b>
              </Typography>
            </Box>
           
          </Stack>
        </Box>



        <Divider>
          <Typography variant="subtitle1" color="text.secondary">
            Assigned AM
          </Typography>
        </Divider>

        <Box sx={{ my: 3 }}>
          {employee && employee.role === "admin" ? (
            (account.assignedTo === null && renderDropdown()) ||
            (account.assignedTo !== null && (
              <Box>
                <Typography variant="body1">
                  Assigned to: {account.assignedTo?.name} [
                  {account.assignedTo?.role} - {account.assignedTo?.email}]
                </Typography>
                <button
                  className="btn bg-dark ml-2 text-white btn-sm"
                  hidden={showDropdown}
                  onClick={toggleDropdown}
                >
                  Change
                </button>
              </Box>
            ))
          ) : (
            <Typography variant="body1">
              Assigned to: {account.assignedTo?.name} [
              {account.assignedTo?.role} - {account.assignedTo?.email}]
            </Typography>
          )}
          {showDropdown && renderDropdown()}
        </Box>
      </Paper>
    </Container>




{/* ------------------------------ */}

      <div className="row mt-3">
        {/* button  and heading */}

        <div className="flex justify-between mb-2">
          <h1 className="h5 mb-3 text-gray-800 justify-content">
            Services / Requirements Offered
          </h1>
        </div>

        <div className="col-sm-12">
          {/* user tabs */}
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="country-tab"
                data-toggle="tab"
                data-target="#country"
                type="button"
                role="tab"
                aria-controls="country"
                aria-selected="true"
              >
                Services Offerred 
                {/* ({account?.servicesCount}) */}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="currency-tab"
                data-toggle="tab"
                data-target="#currency"
                type="button"
                role="tab"
                aria-controls="currency"
                aria-selected="false"
              >
                Requirements 
                 {/* ( {account?.reqCount} ) */}
              </button>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active bg-transparent"
              id="country"
              role="tabpanel"
              aria-labelledby="country-tab"
            >


   {/* fiel upload system for ROUTE */}

            <Box sx={{ textAlign: 'left', m: 2}} className="border-2 border-blue-500 p-4">
              <div className='row'>
                <div className='col-sm-6'> 
            <h2 className='text-black font-bold h4'>Bulk Upload</h2>

          {  processing_SER  ?   

            <div className='p-2 mr-4 text-black '> 
                <ThreeDots
                visible={true}
                height="40"
                width="40"
                color="#4fa94d"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClass=""
                /> Processing....
                </div> 
            :
            <div> 
            <input accept=".xlsx"  ref={openFileResetRefREQ}
             style={{ display: 'none' }} 
            id="contained-button-file"
                type="file" onChange={handleFileChange_SER} />
           <label htmlFor="contained-button-file">
                           <Button variant="outlined" color="primary"  component="span" >   Choose File </Button>
            </label>

          <Button variant="contained" color="secondary"   onClick={handleSubmit_SERFILE} sx={{ m: 2 }}> Submit </Button>
 

            </div> }

            {selectedFile_SER && (  <Typography variant="body1" color="primary" sx={{ mt: 2 }}>  Selected File: {selectedFile_SER.name}  </Typography> ) } 
            
            {errorMessage_SER && ( <Typography variant="body2" color="error" x={{ mt: 2 }}> {errorMessage_SER} </Typography> )}

            { validationErros_SER && validationErros_SER.map((item,index)=>(
              <Typography color="error" key={index}>
              
              {item?.rowNumber} - {item?.errosObjList.map((msg,index)=>(<> [ {msg.message}] </>))}
              <br/>
              </Typography>
            ))}

      </div>
        <div className='col-sm-6 text-black'> 
              Important Note:
                  <ol className='font-normal' style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
                    <li> Refer Sample file -  <a     href={`${url}/file/sample/SampleRouteFormat.xlsx`}  className='text-black' target="_blank" rel="noreferrer"  >
                          Donwload Sample</a>
                  </li>
                    <li> Only Excel file is allowed with ".xlsx" extension</li>
                    <li>Max file size 2mb</li>
                    <li> Quality, Currency,Destination names should exact match with DB.</li>
                    <li>If any validation error comes, Please resolve and upload the file again after refreshing the page</li>
                  </ol>
        </div> 

        </div>
      </Box>



            {/* FIle upload sysmtem for ROUTE */}


            <br/>
              <div className="row mt-3 ">



                <button
                  type="button"
                  className=" btn bg-blue-700 text-sm text-white border-white shadow-lg mb-2"
                  data-toggle="modal"
                  data-target="#addNewAccModal"
                  ref={openSerModalRef}
                >
                  Add new Service 
                  <FontAwesomeIcon className="ml-1" icon={faPlus} />
                </button>

                <ServicesCompo accountIdProps={id} reload={loadComponent} openModal={openSerModalRef}  
                
                serviceEditData={ serviceEditData} setserviceEditData={setserviceEditData} setShowGhostPriceBox={setShowGhostPriceBox}
                />

                      {/* Form start */}
              <div
                className="modal  fade "
                id="addNewAccModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addNewAccModalTitle"
                aria-hidden="true"
                data-backdrop="static"
              >
                <div
                  className="modal-dialog modal-dialog-centered "
                  role="document"
                >
                  <form 
                //  onSubmit={serviceEditData?._id? handleSubmitServices :handleSubmitServices} 
                  onSubmit={handleSubmitServices} 
                  ref={serFormRef}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h2 className="font-semibold text-xl text-gray-600">
                          {serviceEditData?._id ? "Update":"New"} Service Addition 
                        </h2>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={closeRefServ}
                          onClick={resetEditFormDataService}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row    p-1  flex items-center justify-center ">
                          <div className="container  ">
                            <div>
                              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                  <div className="lg:col-span-4">
                                    <div className="grid text-black gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-6">
                                    <DestinationAutoCompo  defaultDestination={selectedDestRoute} setSelectedDest={setSelectedDestRoute}   />
                                    </div>
                                      {/* <div className="md:col-span-3">
                                        <label htmlfor="destinationName">
                                          Destination Name:
                                        </label>
                                        <select   name="destinationName"  className="form-control" >
                                          <option value="">
                                            Select Country
                                          </option>
                                          {country.map((ctry) => (
                                            <option
                                              key={ctry._id}
                                              value={ctry._id}
                                              selected={serviceEditData.destinationName?._id===ctry._id}
                                            >
                                              {ctry.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div> */}

                                      <div className=" md:col-span-3">
                                        <label htmlFor="currency">
                                          Currency:
                                        </label>
                                        <select   name="currency"  className="form-control" >
                                          <option value="">
                                            Select Currency
                                          </option>
                                          {currency.map((curr) => (
                                            <option
                                              key={curr._id}
                                              value={curr._id}
                                              selected={ serviceEditData?.currency?._id===curr._id}
                                            >
                                              {curr.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className=" md:col-span-3">
                                        <label htmlfor="product">
                                        Quality Category:
                                        </label>
                                        <select name="qualityCategory"  className="form-control"  >
                                          <option value="">
                                            Select QualityCategory
                                          </option>
                                          {qCategory.map((product) => (
                                            <option
                                              key={product._id}
                                              value={product._id}
                                              selected={serviceEditData?.qualityCategory?._id===product._id}
                                            >
                                              {product.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                     
                                      <div className=" md:col-span-3">
                                        <label htmlfor="pricing">
                                          Pricing:
                                        </label>
                                        <input
                                          type="text"
                                          id="pricing"
                                          name="pricing"
                                          className="form-control"
                                          defaultValue={serviceEditData.pricing}
                                        />
                                      </div>

                              { employee?.role==="admin" &&
                                 <>  
                                 
                                 {showGhostPriceBox &&  <div className=" md:col-span-3">
                                        <label htmlfor="pricing">
                                          Ghost Price:
                                        </label>
                                        <input
                                          type="text"
                                          id="ghostPricing"
                                          name="ghostPricing"
                                          className="form-control"
                                          
                                          defaultValue={serviceEditData?.ghostPricing || ""}
                                        />
                                      </div>
                                      }

                                      <div className="flex  md:col-span-3">
                                        <label className='mt-4 mr-1' htmlfor="ghostVisible">
                                          Ghost Visible:
                                        </label>
                                        <div className=" mt-4 mr-1 form-check">
                                          <input
                                            type="radio"
                                            id="ghostVisible1"
                                            defaultChecked={
                                              serviceEditData?.ghostVisible ===
                                              true
                                            }
                                            name="ghostVisible"
                                            className="form-check-input"
                                            value="true"
                                            onClick={()=>setShowGhostPriceBox(true)}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlfor="ghostVisible1"
                                          >
                                            Yes
                                          </label>
                                        </div>
                                     
                                        <div className=" mt-4 ml-1  form-check">
                                          <input
                                            type="radio"  id="ghostVisible2"    name="ghostVisible"  
                                            // defaultChecked={   serviceEditData?.ghostVisible ?serviceEditData?.ghostVisible===false : true  }
                                            defaultChecked={   serviceEditData?.ghostVisible===false    }

                                            className="form-check-input"
                                            value="false"
                                            onClick={()=>setShowGhostPriceBox(false)}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlfor="ghostVisible2"
                                          >
                                            No
                                          </label>
                                        </div>
                                      </div>
                                      </>  }

                                      <div className="md:col-span-6  m-2">
                                      <label htmlfor="remarks">Media Type:</label>
                                      <select
                                        type="text"
                                        id="mediaType"
                                        name="mediaType"
                                        className=" form-control"
                                        >    
                                          <option value="">Select Media</option>
                                          <option value="CRTP"     selected={serviceEditData?.mediaType==="CRTP"}  >CRTP</option>
                                          <option value="ORTP"    selected={serviceEditData?.mediaType==="ORTP"}  >ORTP</option>
                                        </select>
                                    </div>

                                   
                                      <div className="md:col-span-6  m-2">
                                      <label htmlfor="remarks">Remarks:</label>
                                      <textarea
                                        type="text"
                                        id="remarks"
                                        name="remarks"
                                        className=" form-control"
                                        defaultValue={  serviceEditData?.remarks }
                                    
                                      ></textarea>
                                    </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer md:col-span-7 flex justify-between  ">
                        <button
                          type="button"
                          className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                          data-dismiss="modal" onClick={resetEditFormDataService}
                        >
                          Close
                        </button>

                        <button
                          className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                          type="submit"
                        >
                          {loading ? <Loader /> : serviceEditData?._id ? "UPDATE" : "ADD"}
                        </button>
                      </div>
                    </div>{" "}
                  </form>
                </div>
              </div>

              {/*  form end */}


              </div>

            
            </div>

            <div  className="tab-pane fade bg-transparent mt-3  "   id="currency"    role="tabpanel"    aria-labelledby="currency-tab"  >
              <div className="col-sm-12  mt-3">



{/* FIle upload sysmtem for requirements */}


  <Box sx={{ textAlign: 'left', m: 2}} className="border-2 border-blue-500 p-4">
          <h2 className='text-black font-bold h4'>Bulk Upload</h2>
<div className='row'>
        <div className='col-sm-6'> 
              {  processing  ?   

                            <div className='p-2 mr-4 text-black'> 
                                <ThreeDots
                                visible={true}
                                height="40"
                                width="40"
                                color="#4fa94d"
                                radius="9"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                /> Processing....
                                  </div>   :   <> 
                            <input accept=".xlsx"  ref={openFileResetRefREQ}
                                    style={{ display: 'none' }} 
                                      id="contained-button-file-req"
                                      type="file" onChange={handleFileChange} />
                            <label htmlFor="contained-button-file-req">
                                <Button variant="outlined" color="primary"  component="span" >   Choose File </Button>
                            </label>
              <Button variant="contained" color="secondary" value="req" onClick={handleSubmit} sx={{ m: 2 }}> Submit </Button>
              </>

              }


      {selectedFile && (  <Typography variant="body1" color="primary" sx={{ mt: 2 }}>  Selected File: {selectedFile.name}  </Typography> ) } 
      
      {errorMessage && ( <Typography variant="body2" color="error" x={{ mt: 2 }}> {errorMessage} </Typography> )}


     { validationErros && validationErros.map((item,index)=>(
        <Typography color="error" key={index}>
        
        {item?.rowNumber} - {item?.errosObjList.map((msg,index)=>(<> [ {msg.message}] </>))}
        <br/>
        </Typography>
      ))}

      </div>  
    <div className='col-sm-6 text-black'> 
       Important Note:
          <ol className='font-normal' style={{ listStyleType: 'decimal', paddingLeft: '20px' }}>
            <li> Refer Sample file - <a href={`${url}/file/sample/SampleReqFormat.xlsx`}  className='text-black' target="_blank" rel="noreferrer"  > Donwload Sample  </a>
          </li>
            <li> Only Excel file is allowed with ".xlsx" extension</li>
            <li>Max file size 2mb</li>
            <li> Quality, Currency,Destination names should exact match with DB.</li>
            <li>If any validation error comes, Please resolve and upload the file again after refreshing the page</li>
          </ol>
       </div>    

      </div>                       
 </Box>



{/* FIle upload sysmtem for requirements */}




              <button
                  type="button"
                  className="btn bg-blue-700 text-sm text-white border-white  shadow-md mb-2"
                  data-toggle="modal"
                  data-target="#addNewReqModa"
                  ref={openReqModalRef}
                >
                  Add new Req
                  <FontAwesomeIcon className="ml-1" icon={faPlus} />
                </button>




                <ReqCompo accountIdProps={id} reload={loadComponent} setReqEditData={setReqEditData} openReqModalRef={openReqModalRef} />
              <div
                className="modal  fade "
                id="addNewReqModa"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="addNewReqModaTitle"
                aria-hidden="true"
                data-backdrop="static"
              >
                <div
                  className="modal-dialog modal-dialog-centered "
                  role="document"
                >
                  <form onSubmit={handleSubmitReq} ref={reqForRef}>
                    <div className="modal-content"> 
                      <div className="modal-header">
                        <h2 className="font-semibold text-xl text-gray-600">
                           Requirement Addition
                        </h2>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          ref={closeRefReq}
                          onClick={resetFormDataReq}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row    p-1  flex items-center justify-center ">
                          <div className="container  ">
                            <div>
                              <div className="bg-white rounded shadow-lg p-4 px-4 md:p-2 mb-6">
                                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                  <div className="lg:col-span-4">
                                    <div className="grid text-black gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-6">
                                    <div className="md:col-span-6">
                                    <DestinationAutoCompo  defaultDestination={selectedDest} setSelectedDest={setSelectedDest}   />
                                    </div>

                                      {/* <div className="md:col-span-3">
                                        <label htmlfor="destinationName">
                                          Destination Name:
                                        </label>
                                        <select
                                         // value={   formDataServices.destinationName ||   ""  }
                                          name="destinationName"
                                         // onChange={handleChange}
                                          className="form-control"
                                        >
                                          <option value="">
                                            Select Country
                                          </option>
                                          {country.map((ctry) => (
                                            <option
                                            selected={reqEditData && reqEditData?.destinationName?._id===ctry._id}
                                              key={ctry._id}
                                              value={ctry._id}
                                            >
                                              {ctry.name}
                                            </option>
                                          ))}
                                        </select>



                                      </div> */}

                                      <div className=" md:col-span-6">
                                        <label htmlfor="currency">
                                          Currency:
                                        </label>
                                        <select
                                        
                                          name="currency"
                                        
                                          className="form-control"
                                        >
                                          <option value="">
                                            Select Currency
                                          </option>
                                          {currency.map((item) => (
                                            <option
                                            selected={reqEditData && reqEditData?.currency?._id===item._id}
                                              key={item._id}
                                              value={item._id}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>


                                      <div className=" md:col-span-6">
                                        <label htmlfor="product">
                                        Quality Category:
                                        </label>
                                        <select name="qualityCategory"  className="form-control"  >
                                          <option value="">
                                            Select QualityCategory
                                          </option>
                                          {qCategory.map((item) => (
                                            <option
                                              key={item._id}
                                              value={item._id}
                                              selected={reqEditData && reqEditData?.qualityCategory?._id===item._id}
                                            >
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      <div className=" md:col-span-3">
                                        <label htmlfor="qualityCategory">
                                          Volume  :
                                        </label>
                                        <input type="text"
                                          name="volume"
                                          className="form-control"
                                          defaultValue={reqEditData && reqEditData?.volume}
                                        />
                                          
                                      </div>

                                      <div className=" md:col-span-6" id="pricing">
                                        <label htmlfor="pricing">
                                          Pricing Range:
                                        </label>
                                       <input
                                        type="text"
                                        id="pricing"
                                        name="startPricing"
                                        className=" md:col-span-2  form-control" placeholder='Start range'
                                        defaultValue={reqEditData && reqEditData?.pricingRange[0]}
                                      />
                                      <input
                                        type="text"
                                        id="pricing"
                                        name="endPricing"
                                        className=" md:col-span-2 form-control" placeholder='End range'
                                        defaultValue={reqEditData && reqEditData?.pricingRange[1]}
                                         
                                      />
                                  </div>


                                  <div className="md:col-span-6  m-2">
                                      <label htmlfor="remarks">Remarks:</label>
                                      <textarea
                                       //ref={textareaRef}
                                        type="text"
                                        id="remarks"
                                        name="remarks"
                                        className=" form-control"
                                        defaultValue={  reqEditData?.remarks }
                                    
                                      ></textarea>
                                    </div>


                            

                                   

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer md:col-span-7 flex justify-between  ">
                        <button
                          type="button"
                          className=" bg-red-600 mr-auto text-md flex justify-content  text-white rounded px-4 py-1 font-medium"
                          data-dismiss="modal"
                          onClick={resetFormDataReq}
                        >
                         
                          Close
                        </button>

                        <button
                          className=" text-md bg-blue-400 flex justify-content  text-white rounded px-4 py-1 font-medium"
                          type="submit"
                        >
                          {loading ? <Loader /> : "SUBMIT"}
                        </button>
                      </div>
                    </div>{" "}
                  </form>
                </div>
              </div>




              </div>

           
            </div>

       
          </div>
        </div>
      </div>
    </div> }
    
  </Sidebar>
);

};

export default Account;